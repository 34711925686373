import {DialogContent} from '@mui/material'
import Button from 'components/Control/Button'
import Dialog from 'components/Display/DialogForm/Dialog'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {
    useI18n,
    useSendMessagesMutation,
    useTriggerStartBotMutation,
} from 'store/Store'
import {btnTextByStep} from './SendTicketToVolunteerUtils'
import styled from 'styled-components'
import FormStep from './FormStep/FormStep'

const SendTicketToVoulnteers = ({ticket, open, onClose}) => {
    const {i18n} = useI18n()
    const [step, setStep] = useState('messageTemplate')
    const [
        sendTemplateMessage,
        {
            error: sendTemplateError,
            data: sendTemplateResponse,
            isLoading: sendTemplateLoading,
            reset,
        },
    ] = useSendMessagesMutation()

    const [
        sendMessage,
        {error: sendError, data: sendResponse, isLoading: sendingMessages},
    ] = useTriggerStartBotMutation()

    const onCloseDialog = (...args) => {
        setStep('messageTemplate')
        form.reset()
        reset()
        onClose(...args)
    }
    const form = useForm({
        defaultValues: {
            details: ticket.detailsComments,
            subject: i18n.t(`tickets.${ticket.subCategory}`),
            volunteers: [],
            isBotSend: false,
        },
        mode: 'all',
    })
    const hasRepsone =
        sendError || sendTemplateError || sendResponse || sendTemplateResponse
    const onSubmit = (data) => {
        if (step === 'messageTemplate') {
            setStep('pickVolunteers')
            return
        }
        if (step === 'pickVolunteers') {
            setStep('sendMessages')
            return
        }
        if (step === 'sendMessages' && !hasRepsone) {
            if (data.isBotSend) {
                sendMessage({
                    volunteers: data.volunteers,
                    ticketId: ticket._id,
                })
            } else {
                sendTemplateMessage({
                    volunteers: data.volunteers,
                    helpRequest: {
                        ticketId: ticket._id,
                        subject: data.subject,
                        details: data.details,
                    },
                })
            }
            return
        }
        onCloseDialog()
    }

    const disableSend =
        !form.formState.isValid || sendTemplateLoading || sendingMessages

    return (
        <Dialog
            {...(step === 'pickVolunteers' && {
                maxWidth: false,
                PaperProps: {
                    style: {
                        width: 'auto',
                        maxWidth: 'none',
                    },
                },
            })}
            open={open}
            onClose={onCloseDialog}
            title={i18n.t('ticketMenu.sendToVolunteers')}>
            <DialogContent>
                <FormStep
                    step={step}
                    ticket={ticket}
                    form={form}
                    sendError={sendError}
                    sendResponse={sendResponse || sendTemplateResponse}
                />
                <DialogActions>
                    <Button color="secondary" onClick={onCloseDialog}>
                        {i18n.t('form.cancel')}
                    </Button>
                    <Button
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={disableSend}>
                        {i18n.t(
                            btnTextByStep(sendingMessages, step, hasRepsone)
                        )}
                    </Button>
                    {step === 'sendMessages' && !hasRepsone && (
                        <Button
                            onClick={form.handleSubmit(() =>
                                onSubmit({...form.getValues(), isBotSend: true})
                            )}
                            disabled={disableSend}>
                            {i18n.t('sendToVolunteersForm.sendBot')}
                        </Button>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default SendTicketToVoulnteers

const DialogActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`
