import React from 'react'
import SendTicketToVoulnteersMessageStep from './SendTicketToVolunteersMessageStep'
import SendTicketToVolunteersPickVolunteersStep from './SendTicketToVolunteersPickVolunteersStep'
import SendTicketToVolunteersSendMessagesStep from './SendTicketToVolunteersSendMessagesStep'

const FormStep = ({step, ticket, form, sendError, sendResponse}) => {
    if (step === 'messageTemplate') {
        return <SendTicketToVoulnteersMessageStep ticket={ticket} form={form} />
    }
    if (step === 'pickVolunteers') {
        return (
            <SendTicketToVolunteersPickVolunteersStep
                ticket={ticket}
                form={form}
            />
        )
    }
    if (step === 'sendMessages') {
        return (
            <SendTicketToVolunteersSendMessagesStep
                ticket={ticket}
                form={form}
                error={sendError}
                response={sendResponse}
            />
        )
    }
}

export default FormStep
