import React, {useEffect, useState} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material'
import {useI18n} from 'store/Store'
import ChatTemplateSender from 'components/Page/Dashboard/Volunteers/chats/ChatTemplateSender'
import {useDispatch} from 'react-redux'
import {getCustomerDataInitiate} from 'store/slices/api/chatbotApi'
import {styled} from '@mui/system'

const SummaryWhatsAppPopApp = ({
    open,
    onClose,
    selectedVolunteers,
    setSelectedVolunteers,
    setCheckBoxState,
    setHeaderCheckboxState,
}) => {
    const [customerIds, setCustomerIds] = useState([])

    const {i18n} = useI18n()
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchAllCustomerData = async () => {
            const promises = selectedVolunteers.map(async (volunteer) => {
                if (!volunteer.phone) return null

                const result = await dispatch(
                    getCustomerDataInitiate({phone: volunteer.phone})
                )

                if (result?.data?.data?.id) {
                    return result.data.data.id
                } else {
                    return null
                }
            })

            const ids = await Promise.all(promises)

            setCustomerIds(ids.filter((id) => id !== null))
        }

        if (open) fetchAllCustomerData()
    }, [selectedVolunteers, open, dispatch])

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{textAlign: 'right'}}>
                {i18n.t('summaryWhatsApp.title')}
            </DialogTitle>
            <StyledLandbotButton
                onClick={() =>
                    window.open(`https://app.landbot.io/gui/chats_v4`)
                }>
                Landbot
            </StyledLandbotButton>
            <DialogContent sx={{direction: 'rtl', textAlign: 'right'}}>
                <StyledVolunteersNumber>
                    {i18n.t('summaryWhatsApp.messageForNumberOfVolunteers', {
                        count: selectedVolunteers.length,
                    })}
                </StyledVolunteersNumber>

                {customerIds.length > 0 ? (
                    <ChatTemplateSender
                        customerId={customerIds}
                        onClose={onClose}
                        setSelectedVolunteers={setSelectedVolunteers}
                        setCheckBoxState={setCheckBoxState}
                        setHeaderCheckboxState={setHeaderCheckboxState}
                    />
                ) : (
                    'Loading customer IDs...'
                )}
            </DialogContent>

            <DialogActions sx={{justifyContent: 'flex-start'}}>
                <StyledCancelButton onClick={onClose} color="secondary">
                    {i18n.t('summaryWhatsApp.cancelButton')}
                </StyledCancelButton>
            </DialogActions>
        </Dialog>
    )
}

export default SummaryWhatsAppPopApp

const StyledLandbotButton = styled(Button)`
    background-color: ${({theme}) => theme.palette.primary.main};
    color: white;
    width: 150px;
    margin-left: auto;
    margin-right: 20px;
    &:hover {
        background-color: ${({theme}) => theme.palette.primary.dark};
    }
`
const StyledVolunteersNumber = styled('p')`
    font-size: 18px;
    color: ${({theme}) => theme.palette.text.primary};
    margin-top: 10px;
`
const StyledCancelButton = styled(Button)`
    font-size: 16px;
    margin-bottom: 10px;
    margin-left: 10px;
    &:hover {
        background-color: #f5f5f4;
    }
`
