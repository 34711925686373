const btnTextByStep = (sendingMessages, step, hasRepsone) => {
    if (sendingMessages) {
        return 'sendToVolunteersForm.sendingMessages'
    }
    if (step === 'messageTemplate') {
        return 'sendToVolunteersForm.pickVolunteers'
    }
    if (step === 'pickVolunteers') {
        return 'sendToVolunteersForm.continue'
    }
    if (step === 'sendMessages') {
        if (hasRepsone) {
            return 'sendToVolunteersForm.close'
        }

        return 'sendToVolunteersForm.sendMessages'
    }
}

export {btnTextByStep}
