import {useState} from 'react'
import {styled} from '@mui/system'
import {
    useGetTemplatesQuery,
    useSendMessageTemplateToCustomerMutation,
    useSendMessageTemplateToMultipleCustomersMutation,
} from 'store/Store'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import Select from 'components/Control/Select'
import {Input, FormLabel, LinearProgress} from '@mui/material'
import SuccessPopup from '../chats/SuccessPopUp'
import FailedPopup from '../chats/FailedPopUp'

const ChatTemplateSender = ({customerId, onClose, setSelectedVolunteers}) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)
    const [showFailedPopup, setShowFailedPopup] = useState(false)

    const {data, isFetching} = useGetTemplatesQuery()
    const single = useSendMessageTemplateToCustomerMutation()
    const multiple = useSendMessageTemplateToMultipleCustomersMutation()
    const templatesMap = data?.data?.templates.reduce((acc, t) => {
        acc[t.name] = t
        return acc
    }, {})
    if (isFetching) return <LinearProgress />
    const handleSendSingleMessage = (params) => {
        const templateId = templatesMap[selectedTemplate]?.id

        single[0]({
            customerId,
            body: {
                template_id: templateId,
                template_params: {
                    body: {params},
                    buttons: [null],
                    template_language: 'he',
                },
            },
        })
            .then(() => setShowSuccessPopup(true))
            .catch(() => setShowFailedPopup(true))
    }

    const handleSendMultiMessages = async (params) => {
        const templateId = templatesMap[selectedTemplate]?.id

        const multiMessageBody = {
            template_id: templateId,
            template_params: {
                body: {params},
                buttons: [null],
                template_language: 'he',
            },
            customerId,
        }

        try {
            await multiple[0]({
                url: 'chatbot/conversation/sendMessage/template/multi',
                body: multiMessageBody,
            })

            setShowSuccessPopup(true)
            setTimeout(() => {
                setShowSuccessPopup(false)
                setSelectedVolunteers([])
                onClose()
            }, 3000)
        } catch (error) {
            console.error('Error sending multi-messages:', error)
            setShowFailedPopup(true)
        }
    }

    return (
        <>
            <SuccessPopup
                open={showSuccessPopup}
                onClose={() => setShowSuccessPopup(false)}
            />

            <FailedPopup
                open={showFailedPopup}
                onClose={() => setShowFailedPopup(false)}
            />
            <TemplatePicker
                templates={data?.data.templates}
                onTemplateSelected={(templateName) => {
                    setSelectedTemplate(templateName)
                }}
            />
            {selectedTemplate ? (
                <TemplateForm
                    sendingMessages={single.isLoading || multiple.isLoading}
                    handleSendSingleMessage={handleSendSingleMessage}
                    handleSendMultiMessages={handleSendMultiMessages}
                    template={templatesMap[selectedTemplate]}
                    customerId={customerId}
                />
            ) : null}
        </>
    )
}

const TemplatePicker = ({templates, onTemplateSelected}) => {
    const options = templates?.map((t) => t.name)
    return (
        <>
            <StyledSelectButton
                doNotTranslate
                label={'בחר תבנית'}
                options={options}
                onChange={(e) => onTemplateSelected(e.target.value)}
            />
        </>
    )
}

const TemplateForm = ({
    template,
    handleSendMultiMessages,
    handleSendSingleMessage,
    sendingMessages,
    customerId,
}) => {
    const [variablesState, setVariables] = useState([])

    return (
        <>
            <StyledTextsTitle>{template.name || ''}</StyledTextsTitle>
            <Texts.MediumText>
                {interpolateString(template.text, variablesState) || ''}
            </Texts.MediumText>
            {Array.from({length: template.params_number || 0}).map((_, i) => (
                <FormLabel key={i}>
                    פרמטר {i}
                    <Input
                        onChange={(e) =>
                            setVariables((curr) => {
                                const newState = [...curr]
                                newState[i] = e.target.value
                                return newState
                            })
                        }
                    />
                </FormLabel>
            ))}
            <div>
                <StyledSendButton
                    disabled={sendingMessages}
                    onClick={() => {
                        if (customerId.length >= 2) {
                            handleSendMultiMessages(variablesState)
                        } else {
                            handleSendSingleMessage(variablesState)
                        }
                    }}>
                    שליחה
                </StyledSendButton>
            </div>
        </>
    )
}

function interpolateString(template, variables) {
    return template.replace(
        /\{\{(\d+)\}\}/g,
        (match, index) => variables[index - 1] || '__'
    )
}

export default ChatTemplateSender

const StyledSelectButton = styled(Select)`
    margin-top: 5px;
    margin-bottom: 10px;
`
const StyledSendButton = styled(Button)`
    margin-top: 20px;
`

const StyledTextsTitle = styled(Texts.Title)`
    margin-bottom: 20px;
`
