import {Checkbox} from '@mui/material'

const TableCheckbox = ({checked = false, onChange, itemId, customerId}) => {
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked
        onChange(isChecked, itemId, customerId)
    }

    return (
        <Checkbox
            color="primary"
            checked={checked}
            onChange={handleCheckboxChange}
        />
    )
}

export default TableCheckbox
