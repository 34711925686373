import TableRowRaw from '@mui/material/TableRow'
import TableCellMui from '@mui/material/TableCell'
import TableCell from './TableCell'
import TableActions from 'components/Display/Table/TableActions'
import {getItemId} from 'services/utils/tables'
import {styled} from '@mui/material'
import TableCheckbox from './TableCheckBox'

const TableRow = ({
    itemName,
    tableName,
    tableKeys,
    tableRowData,
    rowIndex,
    allowEdit,
    editFunction,
    allowDelete,
    onDelete,
    onExpand,
    expandedId,
    keyTypes,
    keyActions,
    onRowClick,
    children,
    deletePrompt,
    customRowActions,
    rowColor,
    checkBoxState,
    handleCheckboxChange,
    setSelectedVolunteers,
}) => {
    const itemId = getItemId(tableRowData)
    const isExpanded = expandedId === itemId
    const showCheckbox = itemName === 'users_volunteer'

    const handleCheckboxToggle = (isChecked) => {
        handleCheckboxChange(itemId, isChecked)

        if (isChecked && tableRowData.phone) {
            setSelectedVolunteers((prev) => {
                const filteredVolunteers = prev.filter(
                    (v) =>
                        v.id !== tableRowData._id &&
                        v.phone !== tableRowData.phone
                )

                return [
                    ...filteredVolunteers,
                    {
                        phone: tableRowData.phone,
                    },
                ]
            })
        } else {
            setSelectedVolunteers((prev) =>
                prev.filter((v) => v.phone !== tableRowData.phone)
            )
        }
    }

    return (
        <>
            <StyledTableRow
                rowcolor={rowColor}
                hover={!!onRowClick}
                onClick={() => onRowClick && onRowClick(itemId)}
                data-testid={`row_number_${rowIndex + 1}`}
                sx={{
                    '&:last-child td, &:last-child th': {border: 0},
                }}>
                {showCheckbox && itemId && (
                    <TableCellMui>
                        <TableCheckbox
                            checked={!!checkBoxState[itemId]}
                            onChange={(isChecked) =>
                                handleCheckboxToggle(isChecked)
                            }
                            itemId={itemId}
                        />
                    </TableCellMui>
                )}
                {tableKeys.map((key) => (
                    <TableCell
                        key={`table_col_${key}_${rowIndex}`}
                        itemKey={key}
                        itemId={itemId}
                        tableRowData={tableRowData}
                        keyTypes={keyTypes}
                        keyActions={keyActions}
                    />
                ))}
                <TableActions
                    itemId={itemId}
                    itemName={itemName}
                    tableName={tableName}
                    tableRowData={tableRowData}
                    allowEdit={allowEdit}
                    editFunction={editFunction}
                    allowDelete={allowDelete}
                    onDelete={onDelete}
                    onExpand={onExpand}
                    isExpanded={isExpanded}
                    deletePrompt={deletePrompt}
                    allowRowClick={!!onRowClick}
                    customRowActions={customRowActions}
                />
            </StyledTableRow>
            {isExpanded && (
                <tr>
                    <td colSpan={tableKeys.length}>{children}</td>
                </tr>
            )}
        </>
    )
}
export default TableRow
const StyledTableRow = styled(TableRowRaw)`
    background-color: ${({rowcolor}) => rowcolor};
`
