import {styled} from '@mui/material'
import {Texts} from 'appearance'
import TicketTabSection from 'components/Page/Dashboard/Tickets/TicketTabSection'
import {formatDateTime} from 'services/utils/dateUtils'
import {useI18n} from 'store/Store'
import {useGetTicketQuery} from 'store/slices/api/ticketsApi'

const TicketStatusDialog = ({ticketId}) => {
    const {i18n} = useI18n()
    const {data: ticket} = useGetTicketQuery(ticketId, {skip: !ticketId})

    const renderSendToVolunteersStatusContainer = (logObject) => (
        <TicketTabSection>
            <DetailsRow>
                <DetailName>
                    {i18n.t('tickets.sendToVolunteersStatus.spreadTo')}:
                </DetailName>
                <DetailValue>
                    {`${logObject.sentCount} ${i18n.t(
                        'tickets.sendToVolunteersStatus.volunteers'
                    )}`}
                </DetailValue>
            </DetailsRow>

            <DetailsRow>
                <DetailName>
                    {i18n.t('tickets.sendToVolunteersStatus.onDate')}:
                </DetailName>
                <DetailValue>{formatDateTime(logObject.sentDate)}</DetailValue>
            </DetailsRow>
        </TicketTabSection>
    )

    return (
        <Container>
            <BoldTitle>
                {ticket?.messageLogs?.length
                    ? i18n.t('tickets.sendToVolunteersStatus.sendingDetails')
                    : i18n.t('tickets.sendToVolunteersStatus.notYetSent')}
            </BoldTitle>
            {ticket?.messageLogs?.map((logObject) =>
                renderSendToVolunteersStatusContainer(logObject)
            )}
        </Container>
    )
}

export default TicketStatusDialog

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
`
const DetailsRow = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
`

const DetailName = styled(Texts.RegularText)`
    font-weight: 500;
    flex-shrink: 0;
`

const DetailValue = styled(Texts.RegularText)`
    font-weight: 300;
`

const BoldTitle = styled(Texts.BoldText)`
    font-size: 18px;
`
