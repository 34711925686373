import {useTheme} from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRowRaw from '@mui/material/TableRow'
import {useI18n, useIsRtl} from 'store/Store'
import {Texts} from 'appearance'
import TableSort from './TableSort'
import TableFilter from './TableFilter'
import {DownloadButton} from '../Table'
import SummaryWhatsAppPopApp from '../../Page/Dashboard/Volunteers/chats/SummaryWhatsUpPopUp'
import TableCheckbox from './TableCheckBox'
import {useState} from 'react'
import styled from 'styled-components'
import WhatsAppIcon from 'assets/icons/whatsappIcon'

const TableHeadRow = ({
    itemName,
    onDelete,
    allowExpand,
    allowEdit,
    tableKeys,
    selectedVolunteers,
    tableData,
    currentPage,
    setCheckBoxState,
    setSelectedVolunteers,
    headerCheckboxState,
    setHeaderCheckboxState,
}) => {
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)

    const theme = useTheme()
    const isRtl = useIsRtl()
    const {i18n} = useI18n()
    const tableName = `${itemName}s`
    const showCheckbox = itemName === 'users_volunteer'
    const isChecked = headerCheckboxState?.[currentPage] || false

    const handleHeaderCheckboxToggle = () => {
        const newCheckedState = !isChecked

        setHeaderCheckboxState((prevState) => ({
            ...prevState,
            [currentPage]: newCheckedState,
        }))

        setCheckBoxState((prevState) => {
            const updatedCheckBoxState = {...prevState}
            tableData.forEach((item) => {
                updatedCheckBoxState[item._id] = newCheckedState
            })
            return updatedCheckBoxState
        })

        if (newCheckedState) {
            setSelectedVolunteers((prev) => [
                ...prev,
                ...tableData
                    .filter((item) => !prev.some((v) => v.phone === item.phone))
                    .map((item) => ({phone: item.phone})),
            ])
        } else {
            setSelectedVolunteers((prev) =>
                prev.filter(
                    (volunteer) =>
                        !tableData.some(
                            (item) => item.phone === volunteer.phone
                        )
                )
            )
        }
    }

    const handleOpenPopUp = () => {
        if (selectedVolunteers.length >= 2) {
            setIsPopUpOpen(true)
        }
    }

    const handleClosePopup = () => {
        setIsPopUpOpen(false)
        setSelectedVolunteers([])
        setCheckBoxState({})
        setHeaderCheckboxState({})
    }

    return (
        <TableHead>
            <TableRowRaw>
                {showCheckbox && (
                    <TableCell>
                        <TableCheckbox
                            checked={isChecked}
                            onChange={handleHeaderCheckboxToggle}
                        />
                    </TableCell>
                )}
                {tableKeys.map((key) => (
                    <TableCell
                        key={`table_head_${key}`}
                        align={isRtl ? 'right' : 'left'}>
                        <Texts.BoldText style={{display: 'inline'}}>
                            {i18n.t(`${tableName}.${key}`)}
                        </Texts.BoldText>
                        <TableSort itemName={itemName} itemKey={key} />
                        <TableFilter itemName={itemName} itemKey={key} />
                    </TableCell>
                ))}

                {(allowEdit || onDelete || allowExpand) &&
                    itemName === 'users_volunteer' && (
                        <TableCell key="actions">
                            <DownloadButton
                                sx={styles.downloadButton(
                                    theme,
                                    selectedVolunteers
                                )}
                                disabled={selectedVolunteers.length < 2}
                                onClick={handleOpenPopUp}>
                                שלח ל -
                                <StyledWhatsappIcon
                                    color={
                                        selectedVolunteers.length < 2
                                            ? theme.palette.grey[400]
                                            : theme.palette.whatsApp
                                    }
                                />
                            </DownloadButton>
                        </TableCell>
                    )}
            </TableRowRaw>
            <SummaryWhatsAppPopApp
                open={isPopUpOpen}
                onClose={handleClosePopup}
                selectedVolunteers={selectedVolunteers}
                setSelectedVolunteers={setSelectedVolunteers}
                setCheckBoxState={setCheckBoxState}
                setHeaderCheckboxState={setHeaderCheckboxState}
            />
        </TableHead>
    )
}

export default TableHeadRow

const styles = {
    downloadButton: (theme, selectedVolunteers) => ({
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        width: '120px',
        padding: '8px 16px',
        borderRadius: '8px',
        fontWeight: 'bold',
        textTransform: 'none',
        marginRight: '5px',
        height: '60px',
        cursor: selectedVolunteers.length >= 2 ? 'pointer' : 'not-allowed',
        transition: 'background-color 0.3s, color 0.3s',

        ...(selectedVolunteers.length >= 2 && {
            '&:hover': {
                backgroundColor: '#f5fbff',
                color: theme.palette.primary.main,
            },
        }),

        '&.Mui-disabled': {
            backgroundColor: '#fff',
            color: theme.palette.grey[400],
            border: `1px solid ${theme.palette.grey[400]}`,
        },
    }),
}

const StyledWhatsappIcon = styled(WhatsAppIcon)`
    margin-left: 5px;
    width: 24px;
    height: 24px;
    fill: ${({theme, disabled}) =>
        disabled ? theme.palette.grey[400] : '#25D366'};
`
