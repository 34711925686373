import {useI18n} from 'store/Store'
import {DataGrid} from '@mui/x-data-grid'
import {useMemo} from 'react'

const SendTicketToVoulnteersMessageStep = ({form, error, response}) => {
    const volunteers = form.watch('volunteers')
    return (
        <VolunteersTable
            volunteers={volunteers.map((v, i) => {
                if (!response?.result) {
                    return v
                }
                const sendResponse = response?.result && response.result[i]
                v.sendState = sendResponse?.reason || sendResponse?.status
                return v
            })}
        />
    )
}

const VolunteersTable = ({volunteers, loading}) => {
    const {i18n} = useI18n()
    const columns = useMemo(
        () => [
            {
                field: 'fullName',
                headerName: i18n.t('sendToVolunteersForm.fullName'),
                sortable: false,
                width: 160,
                valueGetter: (params) =>
                    `${params.row.firstName || ''} ${
                        params.row.lastName || ''
                    }`,
            },
            {
                field: 'address',
                headerName: i18n.t('volunteers.address'),
                sortable: false,
                width: 160,
                valueGetter: (params) =>
                    `${params.row.address?.street || ''} ${
                        params.row.address?.city || ''
                    }`,
            },
            {
                field: 'status',
                headerName: i18n.t('sendToVolunteersForm.status'),
                sortable: true,
                width: 160,
                valueGetter: (params) => {
                    const icon =
                        params.row.sendState === 'success' ? '✅' : '❌'
                    return params.row.sendState
                        ? `${icon}${i18n.t(
                              `sendToVolunteersForm.${params.row.sendState}`
                          )}`
                        : ''
                },
            },
        ],
        [i18n]
    )

    return (
        <DataGrid
            density="compact"
            getRowId={(row) => row._id}
            rows={volunteers}
            columns={columns}
            pagination={false}
            autoHeight
            hideFooter
        />
    )
}

export default SendTicketToVoulnteersMessageStep
