import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const FailedPopup = ({open, onClose}) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
            anchorOrigin={{vertical: 'center', horizontal: 'center'}}
            sx={{
                position: 'fixed',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
            <Alert
                onClose={onClose}
                severity="success"
                sx={{backgroundColor: '#D0312D', color: '#fff', width: '100%'}}>
                בעיה בשליחה
            </Alert>
        </Snackbar>
    )
}

export default FailedPopup
