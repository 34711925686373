import TableBodyRaw from '@mui/material/TableBody'
import TableRow from 'components/Display/Table/TableRow'
import {getItemId} from 'services/utils/tables'

const TableBody = ({
    tableKeys,
    tableData,
    setRowColor,
    handleVolunteerSelection,
    itemName,
    checkBoxState,
    handleCheckboxChange,
    selectedVolunteers,
    setSelectedVolunteers,
    ...otherProps
}) => {
    return (
        <TableBodyRaw>
            {Array.isArray(tableData) &&
                tableData.map((row, index) => {
                    const itemId = getItemId(row)

                    if (!itemId) {
                        console.error(`Row ${index}: Missing unique ID!`)
                        return null
                    }

                    return (
                        <TableRow
                            key={`table_row_${itemId}`}
                            tableRowData={row}
                            tableKeys={tableKeys}
                            rowIndex={index}
                            itemName={itemName}
                            checkBoxState={checkBoxState}
                            handleCheckboxChange={handleCheckboxChange}
                            selectedVolunteers={selectedVolunteers}
                            setSelectedVolunteers={setSelectedVolunteers}
                            rowColor={setRowColor?.(row)}
                            {...otherProps}
                        />
                    )
                })}
        </TableBodyRaw>
    )
}

export default TableBody
