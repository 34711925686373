import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const SuccessPopup = ({open, onClose}) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
            anchorOrigin={{vertical: 'center', horizontal: 'center'}}
            sx={{
                position: 'fixed',
                top: '40%',
                left: '50%',
            }}>
            <Alert
                onClose={onClose}
                severity="success"
                sx={{backgroundColor: '#4caf50', color: '#fff', width: '100%'}}>
                נשלח בהצלחה
            </Alert>
        </Snackbar>
    )
}

export default SuccessPopup
